@use '../../variables' as *;

.titleContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    margin: 0.6rem 0;
    pointer-events: none;

    .flip {
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      animation: flip linear 8s infinite reverse;
      color: rgba($white-colour, 0.8);
      font-family: $font-secondary;
    }
  }

  .main {
    background: linear-gradient(100deg, rgba($orange-colour, 0.9), rgba($red-colour, 0.9), rgba($pink-colour, 0.9), rgba($purple-colour, 0.9), rgba($blue-colour, 0.9), rgba($yellow-colour, 0.9));
    background-size: 500% auto;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: animateTitle 10s ease-in-out 1s infinite alternate;
  }

}

@keyframes animateTitle {
  0% {    
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

@keyframes flip {
  0%,
  90% {
    transform: rotateX(0deg);
  }
  93% {
    transform: rotateX(180deg);
  }
}

// smallest screens
@media only screen and (max-width: 399px) {
  .titleContainer {
    padding: 0 1rem;

    h1 {
      .flip {
        font-size: 0.8rem;
        margin: 0 0.2rem;
      }
    }

    .main {
      font-size: 2.2rem;
      word-wrap: break-word;
      width: 100%;
      letter-spacing: 0.5rem;
    }
  }
}

/* Extra small devices (phones, 400px and up) */
@media only screen and (min-width: 400px) {
  .titleContainer {
    padding: 0 2rem;

    h1 {
      .flip {
        font-size: 1rem;
        margin: 0 0.255rem;
      }
    }

    .main {
      font-size: 2.2rem;
      letter-spacing: 0.5rem;
    }
  }
}

/* Extra small devices (phones, 500px and up) */
@media only screen and (min-width: 500px) {
  .titleContainer {
    padding: 0 2rem;

    h1 {
      .flip {
        font-size: 1.5rem;
        margin: 0 0.325rem;
      }
    }

    .main {
      font-size: 3.2rem;
      letter-spacing: 0.75rem;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .titleContainer {
    padding: 0 2rem;

    h1 {
      .flip {
        font-size: 1.5rem;
        margin: 0 0.375rem;
      }
    }

    .main {
      font-size: 4.5rem;
      letter-spacing: 0.75rem;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .titleContainer {
    padding: 0 2rem;

    h1 {
      .flip {
        font-size: 1.8rem;
        margin: 0 0.525rem;
      }
    }

    .main {
      font-size: 6rem;
      letter-spacing: 0.75rem;

    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .titleContainer {
    padding: 0 2rem;

    h1 {
      .flip {
        font-size: 1.8rem;
        margin: 0 0.525rem;
      }
    }

    .main {
      font-size: 6rem;
      letter-spacing: 0.75rem;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .titleContainer {
    padding: 0 2rem;

    h1 {
      .flip {
        font-size: 1.8rem;
        margin: 0 0.525rem;
      }
    }

    .main {
      font-size: 6rem;
      letter-spacing: 0.75rem;
    }
  }
}

/* XX large devices (large desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .titleContainer {
    padding: 0 2rem;

    h1 {
      .flip {
        font-size: 1.8rem;
        margin: 0 0.525rem;
      }
    }

    .main {
      font-size: 6rem;
      letter-spacing: 0.75rem;
    }
  }
}