@use "../../variables" as *;

nav {
  position: absolute;
  z-index: 5;
  right: 0;

  .listContainer {
    height: 100%;
    overflow: hidden;
  }

  li,
  a {
    font-family: $font-normal;
    font-weight: 400;
    text-decoration: none;
  }

  ul {
    list-style-type: none;
    height: 100%;
    display: flex;
  }

  .iconContainer {
    margin: auto;
    height: 25px;
    width: 30px;
    position: absolute;
    z-index: 5;
    cursor: pointer;
  }
}

// smallest screens
@media only screen and (max-width: 349px) {
  nav {
    // height: 95vh;

    li,
    a {
      font-size: 1.2rem;
      margin: 1.2rem 0;
      color: $white-colour;
    }

    ul {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 3rem 2rem 0 2rem;
    }

    .iconContainer {
      top: 3vh;
      right: 2.5vw;
    }
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      display: none;
      transform: translateX(100%);
      width: 0;
    }
    100% {
      display: flex;
      opacity: 1;
      transform: translateX(0);
      background-color: rgba($black-colour, 0.8);
      width: 50vw;
    }
  }

  @keyframes slideOut {
    0% {
      display: flex;
      opacity: 1;
      background-color: rgba($black-colour, 0.8);
      width: 50vw;
    }
    100% {
      opacity: 0;
      display: none;
      transform: translateX(100%);
      width: 0;
    }
  }

  @keyframes minimise-nav {
    0% {
      height: 95vh;
    }
    99% {height: 95vh;}
    100% {
      height: 3rem;
    }
  }

  @keyframes expand-nav {
    0% {
      height: 3rem;
    }
    1% {
      height: 95vh;
    }
    100% {
      height: 95vh;
    }
  }
}

// smallest screens
// @media only screen and (min-width: 350px) and (max-width: 350px) {
//   nav {

//     li,
//     a {
//       font-size: 1.5rem;
//       margin: 1.2rem 0;
//       color: $white-colour;
//     }

//     ul {
//       flex-direction: column;
//       align-items: flex-start;
//       justify-content: flex-start;
//       padding: 3rem 2rem 0 2rem;
//     }

//     .iconContainer {
//       top: 3vh;
//       right: 2.5vw;
//     }
//   }

//   @keyframes slideIn {
//     0% {
//       opacity: 0;
//       display: flex;
//       transform: translateX(100%);
//       width: 0;
//     }
//     100% {
//       opacity: 1;
//       transform: translateX(0);
//       background-color: rgba($black-colour, 0.8);
//       width: 40vw;
//     }
//   }

//   @keyframes slideOut {
//     0% {
//       opacity: 1;
//       background-color: rgba($black-colour, 0.8);
//       width: 40vw;
//     }
//     100% {
//       opacity: 0;
//       display: none;
//       transform: translateX(100%);
//       width: 0;
//     }
//   }

//   @keyframes minimise-nav {
//     0% {
//       height: 95vh;
//     }
//     99% {height: 95vh;}
//     100% {
//       height: 3rem;
//     }
//   }

//   @keyframes expand-nav {
//     0% {
//       height: 3rem;
//     }
//     1% {
//       height: 95vh;
//     }
//     100% {
//       height: 95vh;
//     }
//   }
// }

/* Small devices (portrait tablets and large phones, 600px and below) */
@media only screen and (min-width: 350px) and (max-width: 599px) {
  nav {

    li,
    a {
      font-size: 1.5rem;
      margin: 1.2rem 0;
      color: $white-colour;
    }

    ul {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 3rem 2rem 0 2rem;
      // width: 40vw;
    }

    .iconContainer {
      top: 3vh;
      right: 2.5vw;
    }
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      display: flex;
      transform: translateX(100%);
      width: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
      background-color: rgba($black-colour, 0.8);
      width: 40vw;
    }
  }

  @keyframes slideOut {
    0% {
      opacity: 1;
      display: flex;
      background-color: rgba($black-colour, 0.8);
      width: 40vw;
    }
    100% {
      opacity: 0;
      display: none;
      transform: translateX(100%);
      width: 0;
    }
  }

  @keyframes minimise-nav {
    0% {
      height: 95vh;
    }
    99% {height: 95vh;}
    100% {
      height: 3rem;
    }
  }

  @keyframes expand-nav {
    0% {
      height: 3rem;
    }
    1% {
      height: 95vh;
    }
    100% {
      height: 95vh;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
  nav {

    li,
    a {
      font-size: 1.5rem;
      margin: 1.2rem 0;
      color: $white-colour;
    }

    ul {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 3rem 2rem 0 2rem;
      // width: 25vw;
    }

    .iconContainer {
      top: 3vh;
      right: 2.5vw;
    }
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      display: flex;
      transform: translateX(100%);
      width: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
      background-color: rgba($black-colour, 0.8);
      width: 25vw;
    }
  }

  @keyframes slideOut {
    0% {
      opacity: 1;
      background-color: rgba($black-colour, 0.8);
      width: 25vw;
    }
    100% {
      opacity: 0;
      display: none;
      transform: translateX(100%);
      width: 0;
    }
  }

  @keyframes minimise-nav {
    0% {
      height: 95vh;
    }
    99% {height: 95vh;}
    100% {
      height: 3rem;
    }
  }

  @keyframes expand-nav {
    0% {
      height: 3rem;
    }
    1% {
      height: 95vh;
    }
    100% {
      height: 95vh;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  nav {

    li,
    a {
      font-size: 1.5rem;
      margin: 1.2rem 0;
      color: $white-colour;
    }

    ul {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 3rem 2rem 0 2rem;
    }

    .iconContainer {
      top: 3vh;
      right: 2.5vw;
    }
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      display: flex;
      transform: translateX(100%);
      width: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
      background-color: rgba($black-colour, 0.8);
      width: 23vw;
    }
  }

  @keyframes slideOut {
    0% {
      opacity: 1;
      background-color: rgba($black-colour, 0.8);
      width: 23vw;
    }
    100% {
      opacity: 0;
      display: none;
      transform: translateX(100%);
      width: 0;
    }
  }

  @keyframes minimise-nav {
    0% {
      height: 95vh;
    }
    99% {height: 95vh;}
    100% {
      height: 3rem;
    }
  }

  @keyframes expand-nav {
    0% {
      height: 3rem;
    }
    1% {
      height: 95vh;
    }
    100% {
      height: 95vh;
    }
  }
}

// laptops
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  nav {
    height: 2rem;
    margin-right: calc(30px + 2.5%);
    top: 7vh;

    li,
    a {
      font-size: 1.4rem;
      margin: 0 1rem;
      color: $black-colour;
    }

    ul {
      padding-top: 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: auto;
    }

    .iconContainer {
      top: 0;
      bottom: 0;
      right: -30px;
    }
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      display: flex;
      transform: translateX(100%);
    }
    80% {
      transform: translateX(-5%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    0% {
      opacity: 1;
      display: flex;
      transform: translateX(0);
    }
    20% {
      transform: translateX(-5%);
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  @keyframes minimise-nav {
    0% { 
    }
   
    100% {
      
    }
  }

  @keyframes expand-nav {
    0% {
      
    }
    
    100% {

    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  nav {
    height: 2rem;
    margin-right: calc(30px + 2.5%);
    top: 7vh;

    li,
    a {
      font-size: 1.6rem;
      margin: 0 1rem;
      color: $black-colour;
    }

    ul {
      padding-top: 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: auto;
    }

    .iconContainer {
      top: 0;
      bottom: 0;
      right: -30px;
    }
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      display: flex;
      transform: translateX(100%);
    }
    80% {
      transform: translateX(-5%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    0% {
      opacity: 1;
    }
    20% {
      transform: translateX(-5%);
    }
    100% {
      opacity: 0;
      display: none;
      transform: translateX(100%);
    }
  }

  @keyframes minimise-nav {
    0% { 
    }
   
    100% {
      
    }
  }

  @keyframes expand-nav {
    0% {
      
    }
    
    100% {

    }
  }
}
