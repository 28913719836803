@import '../../../variables';

.linkContainer {
  display: flex;

  .isActive {
    font-style: italic;
  }
  
  .notActive {
    transition: 0.5s ease-in;
    &:hover {
      text-shadow: 2px 2px 15px rgba($yellow-colour, 0.7), -2px -2px 15px rgba($yellow-colour, 0.7), 2px -2px 15px rgba($yellow-colour, 0.7), -2px 2px 15px rgba($yellow-colour, 0.7);
    }
  }
}