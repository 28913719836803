.badgeGrid {
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 1rem 0;
}

// smallest screens (400px and below)
@media only screen and (max-width: 400px) {
  .badgeGrid {
    grid-template-columns: 1fr;
    width: 80%;
  }
}

// phones and small tablets
@media only screen and (min-width: 401px) {
  .badgeGrid {
    grid-template-columns: 1fr 1fr;
    width: 90%;
  }
}

// tablets, landscape phones
@media only screen and (min-width: 768px) {
  .badgeGrid {
    grid-template-columns: 1fr 1fr 1fr;
    width: 70%;
  }
} 

// laptops
@media only screen and (min-width: 992px) {
  .badgeGrid {
    grid-template-columns: 1fr 1fr 1fr;
    width: 70%;
  }
}

// desktops and above
@media only screen and (min-width: 1200px) {
  .badgeGrid {
    grid-template-columns: 1fr 1fr 1fr;
    width: 70%;
  }
}