@use '../../variables' as *;

.aboutLanding {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  .imageContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .profileImage {
      height: auto;
      object-fit: contain;
      animation: slide-up 0.8s ease-in-out 0.5s forwards;
      opacity: 0;
    }
  }
}

.textContainer {
  width: 100%;

  .statement-name {
    position: absolute;
    bottom: 0rem;
    margin: auto;
    line-height: 0.8;
  }
}

.waterBackground {
  position: relative;
  background: linear-gradient($black-colour, $blue-colour, rgba($blue-colour, 0.6));
}

p {
  font-weight: 600;
}

@keyframes slide-up {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@media only screen and (max-width: 349px) {
  .aboutLanding {
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8rem 0 0 0;

    .profileImage {
      width: 80%;
    }

    #cloud1 {
      position: absolute;
      top: 6%;
      left: calc(50% - 110px);
      width: 60px;
      height: 35px;
    }

    #cloud2 {
      position: absolute;
      bottom: calc(55%);
      right: calc(50% - 100px);
      width: 45px;
      height: 25px;
    }
  }

  .textContainer {
    padding: 0rem 1px 0 1px;
    text-align: center;

    .statement {
      font-size: 1.6rem;    
    }

    .statement-name {
      left: 1px;
      right: 1px;
    }
  }
}

@media only screen and (min-width: 350px) and (max-width: 499px) {
  .aboutLanding {
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8rem 0 0 0;

    .profileImage {
      width: 70%;
    }

    #cloud1 {
      position: absolute;
      top: 6%;
      left: calc(50% - 160px);
      width: 80px;
      height: 45px;
    }

    #cloud2 {
      position: absolute;
      bottom: calc(50%);
      right: calc(50% - 130px);
      width: 50px;
      height: 30px;
    }
  }

  .textContainer {
    padding: 0rem 1rem 0 1rem;
    text-align: center;

    .statement {
      font-size: 1.8rem;    
    }

    .statement-name {
      left: 1rem;
      right: 1rem;
    }
  }
}

@media only screen and (min-width: 500px) {
  .aboutLanding {
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8rem 0 0 0;

    .profileImage {
      width: 65%;
    }

    #cloud1 {
      position: absolute;
      top: 6%;
      left: calc(50% - 190px);
      width: 90px;
      height: 50px;
    }

    #cloud2 {
      position: absolute;
      bottom: calc(50% - 10px);
      right: calc(50% - 180px);
      width: 60px;
      height: 40px;
    }
  }

  .textContainer {
    padding: 0rem 4rem 0 4rem;

    .statement {
      font-size: 2rem;    
    }

    .statement-name {
      left: 4rem;
      right: 4rem;
    }
  }

}

// for portrait, extend min-length of landing
@media only screen and (orientation: portrait) {
  .waterBackground {
    min-height: 60vh;
  }
}

// for portrait, extend min-length of landing
@media only screen and (orientation: landscape) {
  .waterBackground {
    min-height: 500px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .aboutLanding {
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5rem 0 0 0;


    .profileImage {
      width: 50%;
    }

    #cloud1 {
      position: absolute;
      top: 8%;
      left: calc(50% - 210px);
      width: 100px;
      height: 60px;
    }

    #cloud2 {
      position: absolute;
      bottom: calc(50% - 10px);
      right: calc(50% - 180px);
      width: 60px;
      height: 40px;
    }
  }

  .textContainer {
    padding: 0rem 5rem 0 5rem;

    .statement {
      font-size: 2rem;    
    }

    .statement-name {
      left: 5rem;
      right: 5rem;
    }
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .aboutLanding {
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8rem 0 0 0;


    .profileImage {
      width: 40%;
    }

    #cloud1 {
      position: absolute;
      top: 12%;
      left: calc(50% - 230px);
      width: 120px;
      height: 65px;
    }

    #cloud2 {
      position: absolute;
      bottom: calc(50% - 10px);
      right: calc(50% - 180px);
      width: 60px;
      height: 40px;
    }
  }

  .textContainer {
    padding: 0rem 9rem 0 9rem;

    .statement {
      font-size: 2rem;    
    }

    .statement-name {
      left: 9rem;
      right: 9rem;
    }
  }

}

// laptops
@media only screen and (min-width: 992px) {
  .aboutLanding {
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 0 0;

    .profileImage {
      width: 35%;
    }

    #cloud1 {
      position: absolute;
      top: 12%;
      left: calc(50% - 280px);
      width: 140px;
      height: 80px;
    }

    #cloud2 {
      position: absolute;
      bottom: calc(50% - 35px);
      right: calc(50% - 260px);
      width: 80px;
      height: 50px;
    }
  }

  .textContainer {
    padding: 0rem 9rem 0 9rem;

    .statement {
      font-size: 2rem;    
    }

    .statement-name {
      left: 9rem;
      right: 9rem;
    }
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .aboutLanding {
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 0 0;

    .profileImage {
      height: 70%;
      width: auto;
    }

    #cloud1 {
      position: absolute;
      top: 15%;
      left: calc(50% - 300px);
      width: 150px;
      height: 90px;
    }

    #cloud2 {
      position: absolute;
      bottom: calc(50% - 50px);
      right: calc(50% - 280px);
      width: 100px;
      height: 60px;
    }
  }

  .textContainer {
    padding: 0rem 15rem 0 15rem;

    .statement {
      font-size: 2.5rem;    
    }

    .statement-name {
      left: 15rem;
      right: 15rem;
    }
  }
}