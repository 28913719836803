@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

// colours
$black-colour: #081B23;
$orange-colour: #FF6536;
$white-colour: white;
$green-colour: #7EFF73;
$darkgreen-colour: #56B44E;
$pink-colour: #DF57BC;
$blue-colour: #43C1CC;
$yellow-colour: #FFD766;
$red-colour: #E8486D;
$purple-colour: #A491D3;
$deepblue-colour: #1905d9;

// fonts
$font-main: 'Playfair Display', serif;
$font-normal: 'Nunito', sans-serif;
$font-secondary: 'Poiret One', sans-serif;

:export {
  blackcolour: $black-colour;
  orangecolour: $orange-colour;
  whitecolour: $white-colour;
  greencolour: $green-colour;
  pinkcolour: $pink-colour;
  bluecolour: $blue-colour;
  redcolour: $red-colour;
  purplecolour: $purple-colour;
  yellowcolour: $yellow-colour;
  deepbluecolour: $deepblue-colour;
  darkgreencolour: $darkgreen-colour;
  fontmain: $font-main;
  fontnormal: $font-normal;
  fontsecondary: $font-secondary;
}