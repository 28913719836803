@use '../../variables' as *;

.mainContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5vh 0 0 0;

  .icon {
    font-family: $font-secondary;
    color: rgba($black-colour, 0.9);
    letter-spacing: 0.1rem;
    position: absolute;
    z-index: 5;
    top: 7vh;
    left: 2.5%;
  }
}

#content {
  grid-column: 2/3;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

// smallest screens
@media only screen and (max-width: 349px) {
  .icon {
    font-size: 1.8rem;
  }
}


@media only screen and (min-width: 350px) {
  .icon {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 600px) {
  .icon {
    font-size: 2.5rem;
  }
}

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//   .mainContainer {
//     padding: 5vh 0% 0 0;
//   }
// }

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
//   .mainContainer {
//     padding: 5vh 0 0 0;
//   }
// }

// // laptops
// @media only screen and (min-width: 992px) {
//   .mainContainer {
//     padding: 5vh 0 0 0;
//   }
// }

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {
//   .mainContainer {
//     padding: 5vh 0 0 0;
//   }
// }

// /* Extra extra large devices (large desktops, 1400px and up) */
// @media only screen and (min-width: 1400px) {
//   .mainContainer {
//     padding: 5vh 0 0 0;
//   }
// }