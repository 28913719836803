@use '../../../variables' as *;

.navIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .bar {
    width: 30px;
    height: 4px;
    border-radius: 2px;
  }

}

@keyframes topArrow {
  0% {    
  }
  100% {
    transform: rotateZ(45deg) translateX(10px) translateY(-1.5px); 
    width: 15px;
  }
}

@keyframes topArrowReverse {
  0% {
    transform: rotateZ(45deg) translateX(10px) translateY(-1.5px); 
    width: 15px;
  }
  100% {
    transform: rotateZ(0deg) translateX(0px) translateY(0px); 
    width: 30px;
  }
}

@keyframes bottomArrow {
  0% {
  }
  100% {
    transform: rotateZ(-45deg) translateX(10px) translateY(1.5px); width: 15px;
  }
}

@keyframes bottomArrowReverse {
  0% {
    transform: rotateZ(-45deg) translateX(10px) translateY(1.5px); 
    width: 15px;
  }
  100% {
    transform: rotateZ(0deg) translateX(0px) translateY(0px); 
    width: 30px;

  }
}

// below laptops
@media only screen and (max-width: 992px) {
  @keyframes colourTransition {
    0% {
      background: $black-colour;
    }
    100% {
      background: $white-colour;
    }
  }
}

// below laptops
@media only screen and (min-width: 993px) {
  @keyframes colourTransition {
    0% {
      background: $black-colour;
    }
    100% {
      background: $black-colour;
    }
  }
}