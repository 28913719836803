@import "../../variables";

.contactContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;

  .contactTitle {
    display: flex;
    align-items: center;
    color: $orange-colour;
    text-shadow: 8px 8px 2px $black-colour;
    grid-area: title;
    font-family: $font-secondary;
  }

  .floatingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    grid-area: linkedIn;

    .shadow {
      position: absolute;
      margin: auto;
      animation: shadow 1.8s ease-in-out infinite alternate forwards;
      background-image: radial-gradient(
        rgba($black-colour, 0.65) 50%,
        rgba($white-colour, 0) 60%
      );
      transform: translateY(5rem) rotateX(80deg) scale(0.6);
    }

    .linkedInIcon {
      animation: float 1.8s ease-in-out infinite alternate forwards;
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  2% {
    transform: translateY(0);
  }
  98% {
    transform: translateY(-5rem);
  }
  100% {
    transform: translateY(-5rem);
  }
}

@keyframes shadow {
  0% {
    transform: translateY(5rem) rotateX(80deg) scale(1.05);
  }
  2% {
    transform: translateY(5rem) rotateX(80deg) scale(1.05);
  }
  98% {
    transform: translateY(5rem) rotateX(80deg) scale(0.6);
  }
  100% {
    transform: translateY(5rem) rotateX(80deg) scale(0.6);
  }
}

@media only screen and (max-width: 300px) {
  .contactContainer {
    padding: 7vh 2%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 5fr 5fr;
    margin-top: 5rem;
    grid-template-areas:
      "title"
      "form"
      "linkedIn";

    .contactTitle {
      font-size: 2rem;
      flex-direction: row;

      span {
        margin: 0 0.25rem;
      }
    }

    .floatingContainer {
      height: 20rem;
      .shadow {
        width: 6rem;
        height: 6rem;
      }

      .linkedInIcon {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}

@media only screen and (min-width: 301px) and (max-width: 480px) {
  .contactContainer {
    padding: 7vh 5%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 5fr 5fr;
    margin-top: 5rem;
    grid-template-areas:
      "title"
      "form"
      "linkedIn";

    .contactTitle {
      font-size: 2rem;
      flex-direction: row;

      span {
        margin: 0 0.5rem;
      }
    }

    .floatingContainer {
      height: 20rem;
      .shadow {
        width: 6rem;
        height: 6rem;
      }

      .linkedInIcon {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  .contactContainer {
    padding: 7vh 10%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 5fr 5fr;
    grid-template-areas:
      "title"
      "form"
      "linkedIn";

    .contactTitle {
      font-size: 2.5rem;
      flex-direction: row;

      span {
        margin: 0 0.5rem;
      }
    }

    .floatingContainer {
      height: 20rem;
      .shadow {
        width: 6rem;
        height: 6rem;
      }

      .linkedInIcon {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
  .contactContainer {
    padding: 7vh 10%;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr 4fr;
    grid-template-areas:
      "title title"
      "linkedIn form";

    .contactTitle {
      font-size: 3rem;
      flex-direction: row;

      span {
        margin: 0 0.5rem;
      }
    }

    .shadow {
      width: 6rem;
      height: 6rem;
    }

    .linkedInIcon {
      width: 6rem;
      height: 6rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .contactContainer {
    grid-template-columns: 1fr 1fr 3fr;
    grid-template-areas: "title linkedIn form";
    padding: 7vh 10%;

    .contactTitle {
      font-size: 3.5rem;
      flex-direction: column;

      span {
        margin: 0.5rem 0;
      }
    }

    .shadow {
      width: 6rem;
      height: 6rem;
      top: 45%;
    }

    .linkedInIcon {
      width: 6rem;
      height: 6rem;
    }
  }
}
