@import '../../variables';

.projectTileContainer {
  position: relative;
  width: 100%;
  background: $black-colour;
  color: $white-colour;
  display: grid;
  overflow: hidden;
  padding: 0 3.5rem;

  h3 {
    font-family: $font-secondary;
    text-align: center;
    align-self: center;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: transform 0.6s ease-in-out;
    background: $black-colour;
    border-left: 2px solid $white-colour;
    padding: 4% 2rem;
    display: grid;
    grid-template: 
    'text text'
    'button1 button2';

    .tileArrow {
      position: absolute;
      height: 3.5rem;
      width: auto;
      top: 0;
      bottom: 0;
      margin: auto;

      &:hover {
        cursor: pointer;
      }
    }

    .tileText {
      font-size: 1rem;
      text-align: center;
      grid-area: text;
    }

    a {
      align-self: center;
      justify-self: center;
    }

    .projectTileButton {
      background: none;
      font-family: $font-normal;
      color: $white-colour;
      letter-spacing: 0.1rem;
      border-radius: 1rem;
      box-shadow: 0 0 3px $white-colour;
      border: none;
      font-size: 1rem;
      padding: 0.3rem 0.8rem;
      transition: 0.25s ease-in-out;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        box-shadow: 0 1px 5px 1px $orange-colour, 0 -1px 5px 1px $blue-colour;
      }

      :nth-child(1) {
        grid-area: button1;
      }

      :nth-child(2) {
        grid-area: button2;
      }
    }
  }
}

// smallest screens (400px and below)
@media only screen and (max-width: 400px) {
  .projectTileContainer {
    height: 260px;
    margin: 2rem 0;
    h3 {
      font-size: 2rem;
    }
  }
}

// phones and small tablets
@media only screen and (min-width: 401px) {
  .projectTileContainer {
    height: 225px;
    margin: 2rem 0;
    h3 {
      font-size: 2rem;
    }
  }
}

// tablets, landscape phones
@media only screen and (min-width: 768px) {
  .projectTileContainer {
    height: 230px;
    margin: 2rem 0;
    h3 {
      font-size: 2rem;
    }
  }
}

// laptops
@media only screen and (min-width: 992px) {
  .projectTileContainer {
    height: 235px;
    margin: 3rem 0;
    h3 {
      font-size: 3rem;
    }
  }
}

// desktops and above
@media only screen and (min-width: 1200px) {
  .projectTileContainer {
    height: 250px;
    margin: 3rem 0;
    h3 {
      font-size: 3rem;
    }
  }
}