.boatContainer {
  position: absolute;
  margin: auto;
  animation: sail 9s ease-in-out infinite forwards alternate;
  z-index: 4;
  overflow-y: hidden;
  padding: 2px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  svg {
    animation: rotate 2s ease-in-out infinite alternate-reverse;
  }
}

@keyframes sail {
  0% {
    transform: translateX(-2rem);
  }

  100% {
    transform: translateX(2rem);
  }
}

@keyframes rotate {
  0% {transform: rotate(10deg)}
  100% {transform: rotate(-10deg)}
}

// smallest devices
@media only screen and (max-width: 600px) {
  .boatContainer {
    width: 60px;
    height: 70px;
    bottom: -60px;
    left: 25%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .boatContainer {
    width: 70px;
    height: 80px;
    bottom: -70px;
    left: 25%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .boatContainer {
    width: 80px;
    height: 100px;
    bottom: -80px;
    left: 25%;
  }
}

// laptops
@media only screen and (min-width: 992px) {
  .boatContainer {
    width: 90px;
    height: 110px;
    bottom: -100px;
    left: 25%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .boatContainer {
    width: 100px;
    height: 120px;
    bottom: -100px;
    left: 25%;
  }
}

/* Extra extra large devices (large desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .boatContainer {
    width: 100px;
    height: 120px;
    bottom: -100px;
    left: 25%;
  }
}