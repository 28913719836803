@import "../../../variables";

.badge {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    object-fit: contain;
    aspect-ratio: 1/1;
  }

  .textContainer {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: $black-colour;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
  }

  p {
    color: $white-colour;
    text-align: center;
  }
}

.show:nth-child(n) {
  transition-delay: 0ms;
}

.show:nth-child(2n) {
  transition-delay: 200ms;
}

.show:nth-child(3n) {
  transition-delay: 400ms;
}

@keyframes shrink {
  0% {
  }
  100% {
    width: 50%;
  }
}

@keyframes appear {
  0% {
  }
  100% {
    opacity: 0.8;
  }
}

// smallest screens (400px and below)
@media only screen and (max-width: 400px) {
  .badge {
    padding: 1rem;

    p {
      font-size: 1rem;
    }

    img {
      margin: 3rem 2rem;
      width: 70%;
    }
  }
}

// phones and small tablets
@media only screen and (min-width: 401px) {
  p {
    font-size: 1rem;
  }

  .badge {
    padding: 0.8rem;

    img {
      margin: 2rem 1rem;
      width: 60%;
    }
  }
}

// tablets, landscape phones
@media only screen and (min-width: 768px) {
  .badge {
    padding: 1rem;

    p {
      font-size: 1.2rem;
    }

    img {
      margin: 3rem 2rem;
      width: 70%;
    }
  }
}

// laptops
@media only screen and (min-width: 992px) {
  .badge {
    padding: 1rem;
    p {
      font-size: 1.5rem;
    }

    img {
      margin: 3rem 2rem;
      width: 70%;
    }
  }
}

// desktops and above
@media only screen and (min-width: 1200px) {
  .badge {
    padding: 1rem;

    p {
      font-size: 1.5rem;
    }

    img {
      margin: 3rem 2rem;
      width: 70%;
    }
  }
}
