@use '../../variables' as *;

.cloudContainer {
  position: relative;
  margin: auto;
  animation: ease infinite forwards;
  height: 100%;
  width: 100%;

  .cloud {
    position: absolute;
    background: rgba($white-colour, 1);
  }

  .cloudBase {
    width: 100%; 
    height: 50%;
    bottom: 0;
    left: 0;
    box-shadow: 0.6rem 0.6rem rgba($black-colour,0.3);
  }

  .cloudLeft {
    width: 45%; 
    height: 80%;
    left: 20%;	
    bottom: 10%;
  }

  .cloudRight {
    width: 45%; 
    height: 90%;
    right: 15%;	
    bottom: 10%;
  }
  
  
  @keyframes moveVertical {
    0% {
      transform: translate(0, 0) scale(1);
    }
    50% {
      transform: translate(0px, 10px) scale(0.95) ;
    }
  
  }

  @keyframes moveHorizontal {
    0% {
      transform: translate(0, 0) scale(1);
    }
    50% {
      transform: translate(10px, 0px) scale(1.2);
    }
  }

  
}