@use '../../variables' as *;

.sun {
  position: absolute;
  background: linear-gradient(120deg, $orange-colour, $red-colour);
  box-shadow: 0px 10px 60px $orange-colour, 0px 0px 30px $red-colour,;
  border-radius: 50%;
  animation: sun-rotate 30s linear infinite;
  
  .sunray {
    position: absolute;
    margin: auto;
  }

  #sunray1 {
    bottom: var(--value1);
  }

  #sunray2 {
    top: var(--value1);
  }

  #sunray3 {
    right: var(--value1);
  }

  #sunray4 {
    left: var(--value1);
  }

  #sunray5 {
    top: var(--value2);
    right: var(--value2);
  }

  #sunray6 {
    bottom: var(--value2);
    right: var(--value2);
  }

  #sunray7 {
    top: var(--value2);
    left: var(--value2);
  }

  #sunray8 {
    bottom: var(--value2);
    left: var(--value2);
  }
}

@keyframes sun-rotate {
  0% {transform: rotateZ(0deg);}
  100% {transform: rotateZ(360deg);}
}

@keyframes rayExpand1 {
  0% {transform: scaleY(1) rotateZ(180deg);}
  100% {transform: scaleY(1.2) scaleY(0.9) rotateZ(180deg) translateY(-20%);}
}

@keyframes rayExpand2 {
  0% {transform: scaleY(1) rotateZ(0deg);}
  100% {transform: scaleY(1.2) scaleY(0.9) rotateZ(0deg) translateY(-20%);}
}

@keyframes rayExpand3 {
  0% {transform: scaleX(1) rotateZ(90deg);}
  100% {transform: scaleX(1.2) scaleY(0.9) rotateZ(90deg) translateY(-20%);}
}

@keyframes rayExpand4 {
  0% {transform: scaleX(1) rotateZ(-90deg);}
  100% {transform: scaleX(1.2) scaleY(0.9) rotateZ(-90deg) translateY(-20%);}
}

@keyframes rayExpand5 {
  0% {transform: scale(0.6) rotateZ(45deg);}
  100% {transform: scale(1) rotateZ(45deg) translateY(-20%);}
}

@keyframes rayExpand6 {
  0% {transform: scale(0.6) rotateZ(135deg);}
  100% {transform: scale(1) rotateZ(135deg) translateY(-20%);}
}

@keyframes rayExpand7 {
  0% {transform: scale(0.6) rotateZ(-45deg);}
  100% {transform: scale(1) rotateZ(-45deg) translateY(-20%);}
}

@keyframes rayExpand8 {
  0% {transform: scale(0.6) rotateZ(-135deg);}
  100% {transform: scale(1) rotateZ(-135deg) translateY(-20%);}
}

// smallest screens
@media only screen and (max-width: 349px) {
  .sun {
    width: 2.2rem;
    height: 2.2rem;
    top: -8%;
    right: calc(50% - 100px);
  }

  .sunray {
    --value1: -1.5rem;
    --value2: -0.6rem;
  }
}

// small screens
@media only screen and (min-width: 350px) {
  .sun {
    width: 2.5rem;
    height: 2.5rem;
    top: -10%;
    right: calc(50% - 150px);
  }

  .sunray {
    --value1: -1.4rem;
    --value2: -0.8rem;
  }
}

/* Extra small devices (phones, 500px and up) */
@media only screen and (min-width: 500px) {
  .sun {
    width: 3.5rem;
    height: 3.5rem;
    top: -5%;
    right: calc(50% - 200px);
  }

  .sunray {
    --value1: -2.4rem;
    --value2: -1.25rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .sun {
    width: 4rem;
    height: 4rem;
    top: 0%;
    right: calc(50% - 280px);
  }

  .sunray {
    --value1: -3rem;
    --value2: -1.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .sun {
    width: 4.5rem;
    height: 4.5rem;
    top: 1%;
    right: calc(50% - 320px);
  }

  .sunray {
    --value1: -3.5rem;
    --value2: -2rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sun {
    width: 5rem;
    height: 5rem;
    top: 5%;
    right: calc(50% - 350px);
  }
  .sunray {
    --value1: -4rem;
    --value2: -2rem;
  }
}

/* XX large devices (large desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .sun {
    width: 5rem;
    height: 5rem;
    top: 5%;
    right: calc(50% - 350px);
  }
  
  .sunray {
    --value1: -4rem;
    --value2: -2rem;
  }
}