@import "../../variables";

.contactForm {
  display: flex;
  flex-direction: column;
  grid-area: form;
  background: rgba($black-colour, 0.85);
  font-family: $font-normal;
  border-radius: 30px;
  color: $white-colour;

  label {
    font-size: 1rem;
    letter-spacing: 0.1em;
  }

  input,
  textarea {
    background: rgba($white-colour, 0.05);
    border: 1px solid rgba($white-colour, 0.2);
    border-radius: 3px;
    padding: 2px 3px;
    color: $white-colour;
    font-size: 1.1rem;
    letter-spacing: 0.08em;
    margin: 0.25rem 0 2rem 0;
  }

  textarea {
    min-width: 7em;
  }

  button {
    background: none;
    font-family: $font-normal;
    color: $white-colour;
    letter-spacing: 0.1rem;
    border-radius: 1rem;
    box-shadow: 0 0 3px $white-colour;
    border: none;
    font-size: 1rem;
    padding: 0.3rem 0.8rem;
    transition: 0.25s ease-in-out;
    text-decoration: none;
    width: 50%;
    align-self: center;
    margin: 1rem;

    &:enabled {
      &:hover {
        cursor: pointer;
        box-shadow: 0 1px 5px 1px $orange-colour, 0 -1px 5px 1px $blue-colour;
      }
    }

    &:disabled {
      &:hover {
        cursor: auto;
      }
      opacity: 0.6;
    }
  }

  .isSentContainer {
    text-align: center;
  }

  .captureContainer {
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .contactForm {
    width: 90%;
    margin: 5rem 0;
    padding: 2rem 1rem;
  }
}

@media only screen and (min-width: 601px) {
  .contactForm {
    width: 90%;
    padding: 3rem 2rem;
  }
}

@media only screen and (min-width: 992px) {
  .contactForm {
    width: 90%;
    max-width: 400px;
    padding: 3rem 2rem;
  }
}

@media only screen and (min-width: 1200px) {
  .contactForm {
    width: 90%;
    max-width: 400px;
    padding: 3rem 2rem;
  }
}