@import "../../variables";

.projectsTitleContainer {
  width: 100%;

  h2 {
    font-weight: 700;
    color: $pink-colour;
    text-shadow: 12px 12px 3px $black-colour;
    font-family: $font-secondary;
  }
}

.projectTileGrid {
  display: grid;
  width: 100%;
  margin: 5rem 0;
  grid-column-gap: 2rem;
}

@keyframes slideInTitleRight {
  0% {
    transform: translateX(25%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideInTitleLeft {
  0% {
    transform: translateX(-25%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@media only screen and (max-width: 480px) {
  .projectsTitleContainer {
    margin-top: 20%;
    padding: 0 5%;
    h2 {
      font-size: 3rem;
      letter-spacing: 0.4rem;
    }
  }

  .projectTileGrid {
    padding: 0 5%;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  .projectsTitleContainer {
    margin-top: 15%;
    padding: 0 10%;
    h2 {
      font-size: 3rem;
      letter-spacing: 0.5rem;
    }
  }

  .projectTileGrid {
    padding: 0 10%;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
  .projectTileGrid {
    padding: 0 10%;
    grid-template-columns: 1fr;
  }
  .projectsTitleContainer {
    margin-top: 10%;
    padding: 0 10%;
    h2 {
      font-size: 4rem;
      letter-spacing: 1rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .projectsTitleContainer {
    margin-top: 3%;
    padding: 0 15%;
    h2 {
      font-size: 4.5rem;
      letter-spacing: 1.5rem;
    }
  }
  .projectTileGrid {
    padding: 0 6%;
    grid-template-columns: 1fr 1fr;
  }
}
