@use '../../variables' as *;

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15%;
  color: $black-colour;
  font-family: $font-normal;
  text-align: center;
  
  h1 {
    font-size: 6rem;
    font-weight: 700;
    color: $purple-colour;
  }

  p {
    font-size: 3rem;
    font-weight: 500;
    color: $black-colour;
  }

}