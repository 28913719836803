@use '../../variables' as *;

.hillContainer {
  position: absolute;

  .hill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .hill svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100%;
  }

  .flip {
    -webkit-transform: scaleX(-1) rotate(180deg);
    transform: scaleX(-1) rotate(180deg);
    opacity: 0.7;
  }
}