@import "../../variables";

.toastContainer {
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
  height: 70px;
  color: $black-colour;
  padding: 10px 15px;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
  p {
    align-self: center;
    font-size: 1.2rem;
  }

  .crossContainer {
    position: absolute;
    right: 15px;
    top: 10px;
    width: 15px;
    height: 15px;
    &:hover {
      cursor: pointer;
    }
    .cross {
      fill: $black-colour;
    }
  }
}

.toastError {
  background: rgba($red-colour, 1);
  box-shadow: 2px 2px 10px rgba($black-colour, 0.3);  animation: pop-up-toast 1s ease-in-out forwards;
}

.toastSuccess {
  background: rgba($green-colour, 1);
  box-shadow: 2px 2px 10px rgba($black-colour, 0.3);
  animation: pop-up-toast 1s ease-in-out forwards;
}

@keyframes pop-up-toast {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  95% {
    transform: translateY(5);
    opacity: 1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
