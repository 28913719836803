@use '../../variables' as *;

.container {
  position: absolute;
  width: 100%;
  box-shadow: 0px 0px 30px 1px $black-colour;
  z-index: 7;
  top: 0;
  left: 0;
  background-color: $black-colour;
  
  .dotContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .blindEnd {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.5rem;
    background: $black-colour;
    box-shadow: 0 -0px 10px rgba(black, 0.8);
    filter: grayscale(40%);
    -webkit-filter: grayscale(40%);
  }

  .handle {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }
  }
}

@keyframes moveBlindUp {
  0% {height: 90vh;}
  25% {height: 92vh;}
  90% {height: 3vh}
  100% {height: 5vh;}
}

@keyframes moveBlindDown {
  0% {height: 5vh;}
  10% {height: 3vh;}
  80% {height: 92vh;}
  100% {height: 90vh;}
}